<template>
  <div class="box">
    <div class="headline">题库管理</div>
    <div class="second_line">6.4题库导入</div>
    <span class="text">
      进入题库维护界面，【题库管理】→【题库导入】，如下图
    </span>
    <el-image
      style="width: 1200px; height: 400px; margin-top: 8px"
      :src="require('../../assets/Bankimg/47.png')"
      :preview-src-list="[require('../../assets/Bankimg/47.png')]"
    >
    </el-image>
    <span class="text"
      >点击“导入”，选择对应编辑好的Excel表格进行题库数据导入。 注意：
      1）导入题库前请先维护好《公司》、《部门》、《岗位》以及该岗位对应的《职能》等一些基础信息。
      2）已经导入的题库不会再导入第二遍。
      3）导入前，需要在本页面下载题库模板，在模板上面增加题目才能导入。
    </span>
    
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      srcList: "require('../../assets/Bankimg/1.jpg')",
    };
  },
};
</script>
  
  <style  lang="less" scoped>
.box {
  padding-left: 40px;
  .headline {
    font-size: 28px;
    color: #1f2d3d;
    padding-top: 28px;
    line-height: 45px;
    padding-left: 0px ;
  }
  .second_line {
    font-size: 24px;
    line-height: 32px;
    padding: 24px 0 12px;
  }
  .text {
    color: #141e31;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 8px;
  }
  img {
  }
}
</style>